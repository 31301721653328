import React from 'react';
import * as Primitive from '@radix-ui/react-tooltip';

type Props = React.PropsWithChildren<{
  open?: boolean;
  provider?: Primitive.TooltipProviderProps;
  onOpenChange?: (value: boolean) => void;
  delayDuration?: number;
}>;

export const Root = ({ children, provider, delayDuration = 0, ...props }: Props): JSX.Element => (
  <Primitive.Provider {...provider}>
    <Primitive.Root {...props} delayDuration={delayDuration}>
      {children}
    </Primitive.Root>
  </Primitive.Provider>
);

type TriggerProps = Primitive.TooltipTriggerProps & React.RefAttributes<HTMLButtonElement>;

export const Trigger = ({ children, ...props }: React.PropsWithChildren<TriggerProps>) => (
  <Primitive.Trigger {...props}>{children}</Primitive.Trigger>
);

type ContentProps = React.PropsWithChildren<
  Primitive.TooltipContentProps & React.RefAttributes<HTMLDivElement> & Primitive.TooltipContentProps
>;

export const Content = ({
  children,
  alignOffset = 0,
  avoidCollisions = true,
  sideOffset = 0,
  ...props
}: ContentProps): JSX.Element => (
  <Primitive.Content alignOffset={alignOffset} avoidCollisions={avoidCollisions} sideOffset={sideOffset} {...props}>
    {children}
  </Primitive.Content>
);

type ArrowProps = Primitive.TooltipArrowProps & {
  width?: number;
  height?: number;
  offset?: number;
};

export const Arrow = ({ width = 18, height = 8, offset = 8, ...props }: ArrowProps): JSX.Element => (
  <Primitive.Arrow width={width} height={height} offset={offset} {...props} />
);

export const Portal = (props: Primitive.TooltipPortalProps) => <Primitive.Portal {...props} />;
