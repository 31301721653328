import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import styles from './Card.module.css';
import CardHeader from './CardHeader';
import CardFooter from './CardFooter';
import CardBody from './CardBody';
import CardGroup from './CardGroup';
import { Close as CloseIcon } from '@benepass/icons';
import { grayscale } from '@benepass/colors';

const Margins = {
  SMALL: 'small',
  NORMAL: 'normal',
  NONE: 'none',
} as const;

const Vertical = {
  NORMAL: 'unset',
  CENTER: 'center',
} as const;

const Padding = {
  NONE: 'NONE',
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  NORMAL: 'NORMAL',
  LARGE: 'LARGE',
} as const;

const paddingClasses = {
  [Padding.NONE]: 'p-0',
  [Padding.SMALL]: 'py-5 px-4',
  [Padding.MEDIUM]: 'py-5 px-5',
  [Padding.NORMAL]: 'py-7 px-6',
  [Padding.LARGE]: 'p-8',
} as const;

type Props = React.PropsWithChildren<{
  closeable?: boolean;
  defaultVisible?: boolean;
  onClose?: (event: React.MouseEvent<HTMLDivElement> | null | undefined) => void;
  columns?: string;
  padding?: keyof typeof Padding;
  margin?: (typeof Margins)[keyof typeof Margins];
  verticalAlignment?: (typeof Vertical)[keyof typeof Vertical];
  className?: string;
  shadow?: boolean;
  border?: string;
  backgroundColor?: string;
  color?: string;
  width?: string;
  onClick?: () => void;
  borderRadius?: string;
  dataTestId?: string;
}>;

const Card = ({
  children,
  defaultVisible = true,
  onClose,
  closeable = false,
  columns,
  padding = 'NORMAL',
  margin,
  width,
  verticalAlignment,
  className,
  shadow = false,
  backgroundColor,
  onClick,
  color,
  border,
  borderRadius,
  dataTestId,
}: Props): JSX.Element | null => {
  const [visible, setVisible] = useState(defaultVisible);
  const handleCloseClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setVisible(false);
      onClose?.(event);
    },
    [setVisible, onClose]
  );
  const CloseButton = useCallback(() => {
    if (!closeable) {
      return null;
    }

    return (
      <div role="button" className={styles.closeButton} onClick={handleCloseClick} aria-label="Close button">
        <CloseIcon />
      </div>
    );
  }, [closeable, handleCloseClick]);

  if (!visible) {
    return null;
  }

  const cardStyle = {
    gridTemplateColumns: columns,
    alignItems: verticalAlignment,
    width,
    backgroundColor: backgroundColor || grayscale['0'],
    color: color || grayscale['100'],
    margin,
    borderRadius: borderRadius || '8px',
  };

  const cardClasses = clsx(
    'relative w-full',
    paddingClasses[padding],
    {
      'border border-[var(--color-coolgray-20)]': border || shadow,
      'shadow-[0px_2px_4px_rgba(0,0,0,0.05),0px_4px_6px_rgba(0,0,0,0.05)]': shadow || !!onClick,
      'cursor-pointer transition-shadow hover:shadow-4dp focus:shadow-4dp': !!onClick,
      'grid gap-4': columns,
    },
    'benepass_card',
    styles.card,
    className
  );

  const Component = onClick ? 'div' : 'article';
  const interactiveProps = onClick ? { role: 'button', tabIndex: 0 } : {};

  return (
    <Component
      data-testid={dataTestId}
      className={cardClasses}
      style={cardStyle}
      onClick={onClick}
      {...interactiveProps}
    >
      <CloseButton />
      {children}
    </Component>
  );
};

Card.CardHeader = CardHeader;
Card.CardFooter = CardFooter;
Card.CardBody = CardBody;
Card.CardGroup = CardGroup;
Card.Vertical = Vertical;
Card.Margins = Margins;
Card.Padding = Padding;

export { CardHeader, CardFooter, Margins, CardBody, Vertical, CardGroup };
export default Card;
